<template>
  <div>
    <div class="banner poolplan-banner">
      <div class="banner-item">{{ item }}</div>
    </div>
    <div class="container">
      <div class="plan">
        <div class="show-title">
          <div class="show-title-t1">小松鼠全国联营伙伴甄选</div>
          <div class="item-color show-title-third">
            小松鼠诚邀你共同开启环保行业的掘金时代
          </div>
          <div class="show-video">
            <video-player
              class="video-player vjs-custom-skin"
              ref="videoPlayer"
              :playsinline="true"
              :options="playerOptions"
              style="width: 100%"
              @play="onPlayerPlay($event)"
              @pause="onPlayerPause($event)"
              @ended="onPlayerEnded($event)"
            ></video-player>
          </div>
          <div class="service-extend-t1 show-title-third">
            智能垃圾分类回收机
          </div>
          <div class="service-p">
            小松鼠是国内先进的智能垃圾分类回收品牌
            ，根据中国垃圾分类政策标准要求，推出了以智能化、无人化物联网设备抢占社区场景的新商业形态。不但能满足市民日常垃圾投递回收刚需，创造再生资源高毛利收益，还具备互联网+特征，从广告、新零售、流量变现等多渠道为投资者获取可观回报。
          </div>
        </div>
      </div>

      <div class="plan">
        <div class="show-title">
          <div class="show-title-t1">商业逻辑</div>
          <div class="show-title-img row-between">
            <div
              class="show-shadow show-shadow-sp1"
              v-for="item in business"
              :key="item.id"
              :style="'background-image: url(' + item.bgImg + ');'"
            >
              <div class="show-shadow-cont">
                <div class="show-shadow-img">
                  <img :src="item.img" alt="" />
                </div>
                <div
                  class="show-shadow-t1"
                  v-html="item.featureName"
                  style="line-height: 21px"
                ></div>
              </div>
              <div class="show-shadow-bg show-shadow-bg-t1"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="plan">
        <div class="show-title">
          <div class="show-title-t1">样板数据</div>
          <div class="item-color show-title-third">
            截止到2019年底 ，在上海、苏州、安徽：
          </div>
          <div class="show-title-img row-between" style="margin-bottom: 30px">
            <div
              class="show-shadow show-shadow-sp2"
              v-for="item in templateData"
              :key="item.id"
              :style="'background-image: url(' + item.bgImg + ');'"
            >
              <div class="show-shadow-cont show-shadow-cont-sp">
                <div class="show-shadow-t2" v-html="item.t2"></div>
                <div class="show-shadow-t3" v-html="item.t3"></div>
              </div>
              <div class="show-shadow-bg show-shadow-bg-t2"></div>
            </div>
          </div>
          <div
            class="item-color show-title-third"
            style="line-height: 30px; margin: 30px 0"
          >
            样板数据盈利百分百<br />基于运营超过3个月以上的站点数据：
          </div>
          <div class="row-between">
            <div class="operate">
              <div class="row-inline-block operate-img">
                <img
                  src="../../assets/img/poolplan/poolplan-c1-active.png"
                  alt=""
                />
              </div>
              <div class="row-inline-block">
                单台设备回收利润月均<span class="operate-t1">7290</span>
                元（243元<br />/天）平均利润率78%。
              </div>
            </div>
            <div class="operate">
              <div class="row-inline-block operate-img">
                <img
                  src="../../assets/img/poolplan/poolplan-c2-active.png"
                  alt=""
                />
              </div>
              <div class="row-inline-block">
                稳定运营后当月可实现盈亏平衡；平均回<br />本周期<span
                  class="operate-t1"
                  >9</span
                >个月，最快回本周期<span class="operate-t1">6</span>个月。
              </div>
            </div>
          </div>
        </div>
        <div style="height: 120px"></div>
      </div>
    </div>
    <div class="achieve">
      <div class="container-sp service">
        <div class="service-title" style="margin-top: 0">
          加入小松鼠联营方您将获得
        </div>
        <div class="show-title-img row-between" style="margin-bottom: 0">
          <div
            class="show-shadow show-shadow-sp1"
            v-for="item in achineveData"
            :key="item.id"
            :style="'background-image: url(' + item.bgImg + ');'"
          >
            <div class="show-shadow-cont show-shadow-cont1">
              <div class="show-shadow-t5">{{ item.smallTitle }}</div>
              <div class="show-shadow-t4">{{ item.title }}</div>
            </div>
            <div class="show-shadow-bg show-shadow-bg-t1"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="plan">
        <div class="show-title">
          <div class="show-title-t1">我们的竞争力</div>
          <div class="row-between" style="flex-wrap: wrap">
            <div class="compete">
              <div class="compete-img">
                <img src="../../assets/img/poolplan/poolplan-8.png" alt="" />
              </div>
              <div class="show-title-third compete-ma">环保项目 政策赋能</div>
              <div class="compete-p">
                2025年前，全国地级及以上城市要基本建成垃圾分类处理系统。智能垃圾回收行<br />业迎来大规模发展。
              </div>
            </div>
            <div class="compete">
              <div class="compete-img">
                <img src="../../assets/img/poolplan/poolplan-9.png" alt="" />
              </div>
              <div class="show-title-third compete-ma">技术先进 国家认可</div>
              <div class="compete-p">
                小松鼠智能回收是上海市政府两网融合项目。硬件设备及软件系统均为自主研<br />发。
              </div>
            </div>
            <div class="compete">
              <div class="compete-img">
                <img src="../../assets/img/poolplan/poolplan-10.png" alt="" />
              </div>
              <div class="show-title-third compete-ma">
                项目安心落地 联营无后顾之忧
              </div>
              <div class="compete-p">
                专业团队全程培训指导，运营培训一条龙服务。
              </div>
            </div>
            <div class="compete">
              <div class="compete-img">
                <img src="../../assets/img/poolplan/poolplan-11.png" alt="" />
              </div>
              <div class="show-title-third compete-ma">社区下沉 多元收益</div>
              <div class="compete-p">
                只需投入设备就能抢占社区资源的红利项目轻松玩转社区广告、无人售卖、设备<br />销售、二级代理等业务。
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="plan">
        <div class="show-title">
          <div class="show-title-t1">代理商条件</div>
          <div class="row-between">
            <div
              class="condition"
              v-for="item in conditionData"
              :key="item.id"
              :style="'background-image: url(' + item.bgImg + ');'"
            >
              <div class="condition-t1">
                <p>{{ item.t1 }}</p>
                <p>{{ item.t2 }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="plan">
        <div class="show-title">
          <div class="show-title-t1">联营模式</div>
          <div class="row-between" style="flex-wrap: wrap">
            <div class="compete compete-sp">
              <div class="compete-img">
                <img src="../../assets/img/poolplan/poolplan-a1.png" alt="" />
              </div>
              <div class="show-title-third compete-ma">联营企业家资格：</div>
              <div class="compete-p">
                <p>
                  一二线城市以区域为单位，联营商缴纳15万联营合作费用，三四线城市以城市为
                </p>
                <p>
                  单位，联营商缴纳25万元联营合作费用。采购5组智能垃圾分类回收机（10万/
                </p>
                <p>组）。</p>
              </div>
            </div>
            <div class="compete compete-sp">
              <div class="compete-img">
                <img src="../../assets/img/poolplan/poolplan-a2.png" alt="" />
              </div>
              <div class="show-title-third compete-ma">联营企业家权益：</div>
              <div class="compete-p">
                <p>
                  100%垃圾回收收益；100%干湿智能垃圾设备销售利润1.9万/组（成本1.5万/
                </p>
                <p>
                  组，售价3.4万/组）；二级代理销售设备利润1万元/台；首批采购设备的一年保
                </p>
                <p>
                  底广告费1.2万元/组/年；如联营方自主对接广告商，可获得100%广告收入；如
                </p>
                <p>
                  联营方自主获得数据系统开发订单，可获得大数据系统开发订单额的50%
                  。
                </p>
              </div>
            </div>
            <div class="compete" style="height: 450px">
              <div class="compete-img">
                <img src="../../assets/img/service-2.png" alt="" />
              </div>
              <div class="show-title-third compete-ma">联营企业家责任：</div>
              <div class="compete-p">
                设备社区铺设、代理设备销售、发展二级代理、大数据开发技术业务推广。
              </div>
            </div>
            <div class="compete" style="height: 450px">
              <div class="compete-img">
                <img src="../../assets/img/poolplan/poolplan-a4.png" alt="" />
              </div>
              <div class="show-title-third compete-ma">小松鼠负责：</div>
              <div class="compete-p">
                <p>
                  垃圾分类大数据系统开发、运维及管理；对接广告资源及第三方互联网业务；联
                </p>
                <p>
                  营方落地、运营、管理的培训指导；对接各地可回收物销售渠道。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="plan">
        <div class="show-title">
          <div class="show-title-t1">收益预测</div>
          <div class="adv-table1">
            <div class="adv-tr row-between adv-tr-sp">
              <div class="adv-td"></div>
              <div class="adv-td">乐观预测</div>
              <div class="adv-td">中性预测</div>
              <div class="adv-td">保守预测</div>
            </div>
            <div class="adv-tr row-between">
              <div class="adv-td adv-tr-sp">初始投入</div>
              <div class="adv-td">65（万）</div>
              <div class="adv-td">65（万）</div>
              <div class="adv-td">65（万）</div>
            </div>
            <div class="adv-tr row-between">
              <div class="adv-td adv-tr-sp">首年回收收益</div>
              <div class="adv-td">43（万）</div>
              <div class="adv-td">36（万）</div>
              <div class="adv-td">30（万）</div>
            </div>
            <div class="adv-tr row-between">
              <div class="adv-td adv-tr-sp">首年二级扩展收益</div>
              <div class="adv-td">96（万）</div>
              <div class="adv-td">80（万）</div>
              <div class="adv-td">67（万）</div>
            </div>
            <div class="adv-tr row-between">
              <div class="adv-td adv-tr-sp">三年净收益</div>
              <div class="adv-td">425（万）</div>
              <div class="adv-td">354（万）</div>
              <div class="adv-td">295（万）</div>
            </div>
            <div class="adv-tr row-between">
              <div class="adv-td adv-tr-sp">投入回收期</div>
              <div class="adv-td">8（个月）</div>
              <div class="adv-td">10（个月）</div>
              <div class="adv-td">11（个月）</div>
            </div>
          </div>
        </div>
      </div>

      <div style="height: 150px"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      business: [
        {
          id: 0,
          img: require("../../assets/img/poolplan/poolplan-icon1.png"),
          bgImg: require("../../assets/img/poolplan/poolplan-1.png"),
          featureName: "长期抢占 「社区场地资源」",
        },
        {
          id: 1,
          img: require("../../assets/img/poolplan/poolplan-icon2.png"),
          bgImg: require("../../assets/img/poolplan/poolplan-2.png"),
          featureName: "垃圾回收+ 衍生业务多重收入",
        },
        {
          id: 2,
          img: require("../../assets/img/poolplan/poolplan-icon3.png"),
          bgImg: require("../../assets/img/poolplan/poolplan-3.png"),
          featureName: "样板平均回本周期9个月<br/>样板最快回本周期6个月",
        },
        {
          id: 3,
          img: require("../../assets/img/poolplan/poolplan-icon4.png"),
          bgImg: require("../../assets/img/poolplan/poolplan-4.png"),
          featureName: "市政府两网融合项目<br/>享受政府补贴",
        },
      ],
      templateData: [
        {
          id: 0,
          bgImg: require("../../assets/img/poolplan/poolplan-5.png"),
          t2: "<span>400</span><br>多个社区",
          t3: "重点城市已签约",
        },
        {
          id: 1,
          bgImg: require("../../assets/img/poolplan/poolplan-6.png"),
          t2: "<span>400</span><br>多个社区",
          t3: "已铺设完成",
        },
        {
          id: 2,
          bgImg: require("../../assets/img/poolplan/poolplan-7.png"),
          t2: "<span>200</span><br>万人口",
          t3: "已覆盖用户",
        },
      ],
      achineveData: [
        {
          id: 0,
          smallTitle: "政策红利，免费抢占最高20年社区场地资源",
          bgImg: require("../../assets/img/poolplan/poolplan-s1.png"),
          title: "红利",
        },
        {
          id: 1,
          smallTitle: "融入当地政圈，享受政府支持与补贴",
          bgImg: require("../../assets/img/poolplan/poolplan-s2.png"),
          title: "声誉",
        },
        {
          id: 2,
          smallTitle: "当月可实现盈亏平衡，最快回本周期6个月",
          bgImg: require("../../assets/img/poolplan/poolplan-s3.png"),
          title: "收益",
        },
        {
          id: 3,
          smallTitle: "坐拥社区巨大流量，拓展多种衍生业务",
          bgImg: require("../../assets/img/poolplan/poolplan-s4.png"),
          title: "双赢",
        },
      ],
      conditionData: [
        {
          id: 0,
          t1: "独立法人机构",
          t2: "有一定资金实力",
          bgImg: require("../../assets/img/poolplan/poolplan-12.png"),
        },
        {
          id: 1,
          t1: "有一定的",
          t2: "地方资源关系",
          bgImg: require("../../assets/img/poolplan/poolplan-13.png"),
        },
        {
          id: 2,
          t1: "可满足第一期",
          t2: "设备投放数量",
          bgImg: require("../../assets/img/poolplan/poolplan-14.png"),
        },
        {
          id: 3,
          t1: "法人及机构",
          t2: "无违约违法的记录",
          bgImg: require("../../assets/img/poolplan/poolplan-15.png"),
        },
      ],
      item: "全国联营计划",
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], //可选择的播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "",
            src: "https://image.squirrelf.com/poster/xuanchuan1.mp4", //url地址
          },
        ],
        poster: "https://image.squirrelf.com/poster/homepage-a1.png", //你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, //当前时间和持续时间的分隔符
          durationDisplay: true, //显示持续时间
          remainingTimeDisplay: false, //是否显示剩余时间功能
          fullscreenToggle: true, //全屏按钮
        },
      },
    };
  },

  mounted: function () {},
  methods: {},
};
</script>

<style>
.poolplan-banner {
  background-image: url("../../assets/img/poolplan/poolplan-banner.png");
}
.show-shadow-t2 {
  margin-bottom: 66px;
}
.show-shadow-t2 > span {
  font-size: 30px;
}
.show-shadow-t4 {
  font-size: 18px;
  /* font-family: MicrosoftYaHei-Bold, MicrosoftYaHei; */
  font-weight: bold;
  color: #fff;
  margin-top: 16px;
}
.show-shadow-t5 {
  width: 241px;
  height: 60px;
  color: #fff;
  margin: 0 auto;
}
.show-video {
  margin: 30px 0;
}
.plan {
  margin-top: 120px;
}
.show-shadow-sp1 {
  width: 299px;
  height: 171px;
  position: relative;
  cursor: pointer;
}
.show-shadow-bg-t1 {
  width: 299px;
  height: 171px;
}
.show-shadow-sp2 {
  width: 386px;
  height: 240px;
  position: relative;
}
.show-shadow-bg-t2 {
  width: 386px;
  height: 240px;
}
.operate {
  width: 599px;
  border: 1px solid #dde8f4;
  padding: 23px 96px;
  font-size: 18px;
  /* font-family: MicrosoftYaHei-Bold, MicrosoftYaHei; */
  font-weight: bold;
  cursor: pointer;
}
.operate-img {
  width: 44px;
  height: 44px;
  margin-right: 20px;
  background: #fff;
}
.operate-t1 {
  font-size: 26px;
  color: #e9852d;
}
.achieve {
  background: #f4f4f4;
}
.container-sp {
  width: 1200px;
  margin: 0 auto;
  padding: 120px 0;
}
.show-shadow-cont1 {
  width: 100%;
  text-align: center;
  left: 0;
}
.compete {
  width: 599px;
  height: 496px;
}
.compete-sp {
  height: 552px;
}
.compete-img {
  width: 599px;
  height: 342px;
}
.compete-ma {
  margin: 30px 0 10px;
}
.compete-p {
  line-height: 28px;
}
.condition {
  width: 299px;
  height: 342px;
  position: relative;
  text-align: center;
  color: #fff;
}
.condition-t1 {
  width: 100%;
  position: absolute;
  bottom: 20px;
}
</style>